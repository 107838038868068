"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.techatomicPalette = void 0;
exports.techatomicPalette = {
    neutral: {
        100: '#FDFDFD',
        200: '#F8F8F8',
        300: '#F3F3F3',
        400: '#E4E4E4',
        500: '#D0D0D0',
        600: '#B9B9B9',
        700: '#9F9F9F',
        800: '#848484',
        900: '#6A6A6A',
        1000: '#515151',
        1100: '#3B3B3B',
        1200: '#292929',
        1300: '#1A1A1A',
        1400: '#0F0F0F',
        1500: '#040404',
    },
    primary: {
        100: '#FFFDFD',
        200: '#FEF6F9',
        300: '#FEEFF4',
        400: '#FCDBE6',
        500: '#FCB0D4',
        600: '#F99EBF',
        700: '#F772A7',
        800: '#EA3B8E',
        900: '#C52672',
        1000: '#981D58',
        1100: '#701641',
        1200: '#4F0F2E',
        1300: '#350A1E',
        1400: '#1F0612',
        1500: '#0A0206',
    },
    secondary: {
        100: '#FDFDFD',
        200: '#F8F8F8',
        300: '#F3F3F3',
        400: '#E4E4E4',
        500: '#D0D0D0',
        600: '#B9B9B9',
        700: '#9F9F9F',
        800: '#848484',
        900: '#6A6A6A',
        1000: '#515151',
        1100: '#3B3B3B',
        1200: '#292929',
        1300: '#1A1A1A',
        1400: '#0F0F0F',
        1500: '#040404',
    },
    error: {
        100: '#FEFDFD',
        200: '#FDF7F7',
        300: '#FBF0F0',
        400: '#F7DEDE',
        500: '#F1C6C6',
        600: '#EBA7A7',
        700: '#E58383',
        800: '#DF5656',
        900: '#CD2424',
        1000: '#9F1C1C',
        1100: '#761515',
        1200: '#530F0F',
        1300: '#380A0A',
        1400: '#220606',
        1500: '#0C0202',
    },
    success: {
        100: '#FCFEFB',
        200: '#F3FBEE',
        300: '#E9F7E0',
        400: '#CEEEB6',
        500: '#A5E16E',
        600: '#83CC24',
        700: '#71B01F',
        800: '#5E921A',
        900: '#4B7615',
        1000: '#3A5A10',
        1100: '#2A420C',
        1200: '#1D2E08',
        1300: '#131E05',
        1400: '#0B1103',
        1500: '#030401',
    },
    warning: {
        100: '#FFFDFB',
        200: '#FFF7ED',
        300: '#FFF1DF',
        400: '#FFDFB3',
        500: '#FFC765',
        600: '#ECAE1D',
        700: '#CC9619',
        800: '#A97D15',
        900: '#886411',
        1000: '#684D0D',
        1100: '#4C3809',
        1200: '#352706',
        1300: '#221904',
        1400: '#140F02',
        1500: '#050301',
    },
    info: {
        100: '#FCFDFE',
        200: '#F4F9FC',
        300: '#EBF4FA',
        400: '#D3E7F4',
        500: '#B0D6EC',
        600: '#7FC1E4',
        700: '#2CABDB',
        800: '#218EB7',
        900: '#1A7293',
        1000: '#145871',
        1100: '#0F4053',
        1200: '#0A2C39',
        1300: '#071D25',
        1400: '#041116',
        1500: '#010506',
    }
};
