import { techatomicPalette } from './palettes/techatomicPalette';
import { brandsPalette } from './palettes/brandsPalette';

const commonTheme = {
    /*PALETTES*/
    palette: {
        techatomic: techatomicPalette,
        brands: brandsPalette
    },
    /*TYPOGRAPHY*/
    typography: {
        allVariants: {
            fontFamily: 'TT Norms Pro, serif'
        }
    }
};

export default commonTheme;