import React, { useEffect, useState } from 'react';
import './taSpeedDial.css';

/*MUI COMPONENTS*/
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

/*ICON COMPONENTS*/
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DarkThemeIcon from '@mui/icons-material/Brightness3';
import LightThemeIcon from '@mui/icons-material/Brightness7';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

/*THEME COMPONENTS*/
import { useColorMode } from '../../../assets/themes/components/ColorModeContext';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

const CustomSpeedDialAction = styled(SpeedDialAction)(({ theme }) => ({
    transition: 'opacity 0.3s ease-in-out, border-radius 0.3s ease-in-out, backgroundColor 0.3s ease-in-out',
    '&.visible': {
        opacity: 1
    },
    '&.hidden': {
        opacity: 0
    },
}));

const TaSpeedDial = () => {
    const theme = useTheme();

    const { toggleColorMode, isDarkMode } = useColorMode();

    /*SPEED DIAL - FAB STYLES*/
    const fabColor = !isDarkMode
        ? theme.palette.techatomic.neutral[1500]
        : theme.palette.techatomic.neutral[1500];
    const fabBackgroundColor = !isDarkMode
        ? theme.palette.techatomic.primary[800]
        : theme.palette.techatomic.primary[800];
    const fabBackgroundColorHover = !isDarkMode
        ? theme.palette.techatomic.primary[900]
        : theme.palette.techatomic.primary[900];

    /*SPEED DIAL - ACTION BUTTON STYLES*/
    const speedDialActionColor = !isDarkMode
        ? theme.palette.techatomic.neutral[200]
        : theme.palette.techatomic.neutral[200];
    const speedDialActionBackgroundColor = !isDarkMode
        ? theme.palette.techatomic.neutral[1000]
        : theme.palette.techatomic.neutral[1000];
    const speedDialActionBackgroundColorHover = !isDarkMode
        ? theme.palette.techatomic.neutral[900]
        : theme.palette.techatomic.neutral[900];

    const [showButton, setShowButton] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [bottomValue, setBottomValue] = useState('25px');

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 200) {
                setShowButton(true);
            }
            else {
                setShowButton(false);
            }

            const footer = document.querySelector('footer');
            if (footer) {
                const footerTop = footer.getBoundingClientRect().top;
                const footerHeight = footer.clientHeight;
                const windowHeight = window.innerHeight;
                const threshold = 28;

                if (footerTop < windowHeight) {
                    setBottomValue(`${windowHeight - footerTop + threshold}px`);
                }
                else {
                    setBottomValue('28px');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    return (
        <Box
            sx={{
                opacity: showButton ? 1 : 0,
                visibility: showButton ? 'visible' : 'hidden',
                transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
                position: 'fixed',
                bottom: {
                    xs: bottomValue
                },
                right: {
                    xs: 8,
                    sm: 15
                }
            }}
        >
            <SpeedDial
                ariaLabel='TA Speed Dial'
                sx={{
                    //position: 'fixed',
                    //bottom: bottomValue,
                    //right: 17,
                    transition: 'bottom 0.3s ease-in-out',
                    '& .MuiFab-primary': {
                        width: '50px',
                        maxHeight: '50px',
                        borderRadius: isOpen ? '5px 5px 5px 12px' : '5px 12px 5px 12px',
                        color: fabColor,
                        backgroundColor: fabBackgroundColor,
                        transition: 'border-radius 0.3s ease-in-out, background-color 0.3s ease-in-out',
                        '&:hover': {
                            backgroundColor: fabBackgroundColorHover,
                        },
                    },
                    '& .MuiSpeedDial-actions': {
                        paddingBottom: '35px'
                    },
                    '& .MuiSpeedDialAction-fab': {
                        width: '50px',
                        height: '50px',
                        color: speedDialActionColor,
                        backgroundColor: speedDialActionBackgroundColor,
                        transition: 'border-radius 0.3s ease-in-out, background-color 0.3 ease-in-out',
                        '&:hover': {
                            backgroundColor: speedDialActionBackgroundColorHover,
                        },
                    },
                    '& .MuiTooltip-popper': {
                        backgroundColor: '#FF0000'
                    }
                }}
                icon={<SpeedDialIcon />}
                onOpen={handleOpen}
                onClose={handleClose}
                open={isOpen}
            >
                {/*THEME TOGGLE BUTTON*/}
                {/*NOTE: This has been deprecated until a light theme is designed by Ashley.*/}
                {/*<CustomSpeedDialAction*/}
                {/*    onClick={toggleColorMode}*/}
                {/*    icon={isDarkMode ? <LightThemeIcon /> : <DarkThemeIcon />}*/}
                {/*    tooltipTitle={isDarkMode ? 'Toggle Light Mode' : 'Toggle Dark Mode'}*/}
                {/*    sx={{*/}
                {/*        my: 0.25,*/}
                {/*        borderRadius: `${showButton ? '5px' : '5px 12px 5px 5px'}`*/}

                {/*    }}*/}
                {/*    className={isOpen ? 'visible' : 'hidden'}*/}
                {/*/>*/}

                {/*SCROLL TO TOP BUTTON*/}
                <CustomSpeedDialAction
                    onClick={scrollToTop}
                    icon={<ArrowUpwardIcon />}
                    tooltipTitle='Scroll to Top'
                    sx={{
                        my: 0.25,
                        borderRadius: '5px 12px 5px 5px'
                    }}
                    className={isOpen && showButton ? 'visible' : 'hidden'}
                />
            </SpeedDial>
        </Box>        
    );
};

export default TaSpeedDial;