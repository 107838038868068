import './footer.css';

/*MUI COMPONENTS*/
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

/*ICON COMPONENTS*/
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

/*THEME COMPONENTS*/
import darkThemeLogo from 'assets/images/techatomic/logos/png/ta-logo-light.png';
import lightThemeLogo from 'assets/images/techatomic/logos/png/ta-logo-light.png';

import { useTheme } from '@mui/material/styles';
import { useColorMode } from '../../../assets/themes/components/ColorModeContext';
import TaButton from "../../common/TaButton/TaButton";

/*COPYRIGHT INFORMATION*/
function Copyright() {
    const theme = useTheme();

    const copyright = String.fromCodePoint(0x00A9);

    return (
        <div className="copyright">
            <Typography
                variant="body1"
                sx={{
                    mb: 4,
                    color: theme.palette.copyright.text
                }}
            >
                Copyright {copyright} {new Date().getFullYear()} Techatomic. All Rights Reserved.
            </Typography>

            {/*<Typography>*/}
            {/*    Trademarks and brands are the property of their respective owners.*/}
            {/*</Typography>*/}
        </div>
    );
}

function Footer() {
    const theme = useTheme();
    const { isDarkMode } = useColorMode();
    const logo = !isDarkMode ? darkThemeLogo : lightThemeLogo;
    /*const icon = !isDarkMode ? darkThemeIcon : lightThemeIcon;*/
    const instagramGradient = {
        background: 'radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)'
    };

    return (
        <Box
            component="footer"
            className="footer"
            sx={{
                bgcolor: theme.palette.footer,
                backgroundImage: 'none'
            }}
        >
            <Container
                maxWidth="xl"
                sx={{
                    pt: 4
                }}
            >
                {/*ABOVE DIVIDER*/}
                <Grid
                    container
                    justifyContent="center"
                >
                    {/*TECHATOMIC LOGO*/}
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            mb: 4,
                            textAlign: {
                                xs: 'center',
                                md: 'left'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: {
                                    xs: 'center',
                                    md: 'flex-start'
                                },
                                alignItems: {
                                    xs: 'center',
                                    md: 'flex-start'
                                },
                                flexDirection: {
                                    xs: 'column',
                                    md: 'row'
                                },
                                height: '100%'
                            }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: 30
                                }}
                                alt="Techatomic"
                                src={logo}
                            />
                        </Box>
                    </Grid>

                    {/*SITE MAP*/}
                    <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{
                            mb: 4,
                            textAlign: {
                                xs: 'center',
                                md: 'right'
                            }
                        }}
                    >
                        <Box>
                            <Grid
                                container
                                justifyContent={{
                                    xs: 'normal',
                                    sm: 'space-evenly',
                                    md: 'flex-end'
                                }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    textAlign: 'left'
                                }}
                            >
                                {/*TECHATOMIC LINKS*/}
                                <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    sx={{
                                        mb: {
                                            xs: 4,
                                            sm: 0
                                        },
                                        mr: {
                                            xs: 0,
                                            md: 6,
                                            lg: 9,
                                            xl: 12
                                        },
                                        maxWidth: {
                                            xs: '100%',
                                            sm: '136px',
                                            md: '136px'
                                        }
                                    }}
                                >
                                    {/*HEADING*/}
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: {
                                                xs: '1.5rem',
                                                md: '1.5rem'
                                            },
                                            lineHeight: {
                                                xs: '2rem',
                                                md: '2rem',
                                            },
                                            mb: 1
                                        }}
                                    >
                                        Techatomic
                                    </Typography>

                                    {/*MISSION STATEMENT*/}
                                    <Box
                                        component={Link}
                                        to={`/#`}
                                        sx={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: theme.palette.footerTextLink.active,
                                                transition: 'all 0.3s ease-in-out',
                                                '&:hover': {
                                                    color: theme.palette.footerTextLink.hover
                                                },
                                            }}
                                        >
                                            Mission Statement
                                        </Typography>
                                    </Box>

                                    {/*MEET THE TEAM*/}
                                    <Box
                                        component={Link}
                                        to={`/#`}
                                        sx={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: theme.palette.footerTextLink.active,
                                                transition: 'all 0.3s ease-in-out',
                                                '&:hover': {
                                                    color: theme.palette.footerTextLink.hover
                                                },
                                            }}
                                        >
                                            Meet the Team
                                        </Typography>
                                    </Box>

                                    {/*OUR PRODUCT SUITE*/}
                                    <Box
                                        component={Link}
                                        to={`/#`}
                                        sx={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: theme.palette.footerTextLink.active,
                                                transition: 'all 0.3s ease-in-out',
                                                '&:hover': {
                                                    color: theme.palette.footerTextLink.hover
                                                },
                                            }}
                                        >
                                            Our Product Suite
                                        </Typography>
                                    </Box>

                                    {/*NEWSLETTER*/}
                                    <Box
                                        component={Link}
                                        to={`/#`}
                                        sx={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: theme.palette.footerTextLink.active,
                                                transition: 'all 0.3s ease-in-out',
                                                '&:hover': {
                                                    color: theme.palette.footerTextLink.hover
                                                },
                                            }}
                                        >
                                            Newsletter
                                        </Typography>
                                    </Box>
                                </Grid>

                                {/*ACCOUNT LINKS*/}
                                {/*<Grid*/}
                                {/*    item*/}
                                {/*    xs={6}*/}
                                {/*    sm={4}*/}
                                {/*    sx={{*/}
                                {/*        mb: {*/}
                                {/*            xs: 4,*/}
                                {/*            sm: 0*/}
                                {/*        },*/}
                                {/*        mr: {*/}
                                {/*            xs: 0,*/}
                                {/*            md: 6,*/}
                                {/*            lg: 9,*/}
                                {/*            xl: 12*/}
                                {/*        },*/}
                                {/*        maxWidth: {*/}
                                {/*            xs: '100%',*/}
                                {/*            sm: '131px',*/}
                                {/*            md: '131px'*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*>*/}
                                    {/*HEADING*/}
                                    {/*<Typography*/}
                                    {/*    sx={{*/}
                                    {/*        fontWeight: 400,*/}
                                    {/*        fontSize: {*/}
                                    {/*            xs: '1.5rem',*/}
                                    {/*            md: '1.5rem'*/}
                                    {/*        },*/}
                                    {/*        lineHeight: {*/}
                                    {/*            xs: '2rem',*/}
                                    {/*            md: '2rem',*/}
                                    {/*        },*/}
                                    {/*        mb: 1*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    My Account*/}
                                    {/*</Typography>*/}

                                    {/*MY DASHBOARD*/}
                                    {/*<Box*/}
                                    {/*    component={Link}*/}
                                    {/*    to={`/#`}*/}
                                    {/*    sx={{*/}
                                    {/*        textDecoration: 'none',*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <Typography*/}
                                    {/*        variant="body1"*/}
                                    {/*        sx={{*/}
                                    {/*            color: theme.palette.footerTextLink.active,*/}
                                    {/*            transition: 'all 0.3s ease-in-out',*/}
                                    {/*            '&:hover': {*/}
                                    {/*                color: theme.palette.footerTextLink.hover*/}
                                    {/*            },*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        Dashboard*/}
                                    {/*    </Typography>*/}
                                    {/*</Box>*/}

                                    {/*MY PRODUCT LICENSES*/}
                                    {/*<Box*/}
                                    {/*    component={Link}*/}
                                    {/*    to={`/license`}*/}
                                    {/*    sx={{*/}
                                    {/*        textDecoration: 'none',*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <Typography*/}
                                    {/*        variant="body1"*/}
                                    {/*        sx={{*/}
                                    {/*            color: theme.palette.footerTextLink.active,*/}
                                    {/*            transition: 'all 0.3s ease-in-out',*/}
                                    {/*            '&:hover': {*/}
                                    {/*                color: theme.palette.footerTextLink.hover*/}
                                    {/*            },*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        Product Licenses*/}
                                    {/*    </Typography>*/}
                                    {/*</Box>*/}

                                    {/*MY SECURITY*/}
                                    {/*<Box*/}
                                    {/*    component={Link}*/}
                                    {/*    to={`/#`}*/}
                                    {/*    sx={{*/}
                                    {/*        textDecoration: 'none',*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <Typography*/}
                                    {/*        variant="body1"*/}
                                    {/*        sx={{*/}
                                    {/*            color: theme.palette.footerTextLink.active,*/}
                                    {/*            transition: 'all 0.3s ease-in-out',*/}
                                    {/*            '&:hover': {*/}
                                    {/*                color: theme.palette.footerTextLink.hover*/}
                                    {/*            },*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        Security*/}
                                    {/*    </Typography>*/}
                                    {/*</Box>*/}

                                    {/*MY SETTINGS*/}
                                    {/*<Box*/}
                                    {/*    component={Link}*/}
                                    {/*    to={`/#`}*/}
                                    {/*    sx={{*/}
                                    {/*        textDecoration: 'none',*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <Typography*/}
                                    {/*        variant="body1"*/}
                                    {/*        sx={{*/}
                                    {/*            color: theme.palette.footerTextLink.active,*/}
                                    {/*            transition: 'all 0.3s ease-in-out',*/}
                                    {/*            '&:hover': {*/}
                                    {/*                color: theme.palette.footerTextLink.hover*/}
                                    {/*            },*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        Settings*/}
                                    {/*    </Typography>*/}
                                    {/*</Box>*/}
                                {/*</Grid>*/}

                                {/*LEGAL LINKS*/}
                                <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    sx={{                                        
                                        maxWidth: {
                                            xs: '100%',
                                            sm: '120px',
                                            md: '120px'
                                        }
                                    }}
                                >
                                    {/*HEADING*/}
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: {
                                                xs: '1.5rem',
                                                md: '1.5rem'
                                            },
                                            lineHeight: {
                                                xs: '2rem',
                                                md: '2rem',
                                            },
                                            mb: 1
                                        }}
                                    >
                                        Legal
                                    </Typography>

                                    {/*LICENSE*/}
                                    <Box
                                        component={Link}
                                        to={`/license`}
                                        sx={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: theme.palette.footerTextLink.active,
                                                transition: 'all 0.3s ease-in-out',
                                                '&:hover': {
                                                    color: theme.palette.footerTextLink.hover
                                                },
                                            }}
                                        >
                                            License
                                        </Typography>
                                    </Box>

                                    {/*TERMS OF SERVICE*/}
                                    <Box
                                        component={Link}
                                        to={`/termsOfService`}
                                        sx={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: theme.palette.footerTextLink.active,
                                                transition: 'all 0.3s ease-in-out',
                                                '&:hover': {
                                                    color: theme.palette.footerTextLink.hover
                                                },
                                            }}
                                        >
                                            Terms of Service
                                        </Typography>
                                    </Box>

                                    {/*PRIVACY POLICY*/}
                                    <Box
                                        component={Link}
                                        to={`/privacyPolicy`}
                                        sx={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: theme.palette.footerTextLink.active,
                                                transition: 'all 0.3s ease-in-out',
                                                '&:hover': {
                                                    color: theme.palette.footerTextLink.hover
                                                },
                                            }}
                                        >
                                            Privacy Policy
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>              

                {/*DIVIDER*/}
                <Grid
                    item
                    xs={12}
                    sx={{
                        mb: 4
                    }}
                >
                    <Divider
                        sx={{
                            borderColor: theme.palette.footerDivider
                        }}
                    />
                </Grid>

                {/*BELOW DIVIDER*/}
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                >
                    {/*COPYRIGHT*/}
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            textAlign: {
                                xs: 'center',
                                md: 'left'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: {
                                    xs: 'center',
                                    md: 'flex-start'
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 0,
                                }}
                            >
                                <Copyright />
                            </Box>
                        </Box>
                    </Grid>

                    {/*SOCIAL MEDIA LINKS*/}
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            mb: 4,
                            textAlign: {
                                xs: 'center',
                                md: 'right'
                            }
                        }}
                    >
                        <Box>
                            {/*FACEBOOK*/}
                            <TaButton
                                sx={{
                                    color: theme.palette.techatomic.neutral[100],
                                    '&:hover': {
                                        color: theme.palette.techatomic.neutral[100],
                                        backgroundColor: theme.palette.brands.facebook
                                    },
                                    mx: '.25rem'
                                }}
                                style={{
                                    minWidth: '10px',
                                    width: '36px'
                                }}
                            >
                                <FacebookIcon />
                            </TaButton>

                            {/*X*/}
                            <TaButton
                                size='medium'
                                sx={{
                                    color: theme.palette.techatomic.neutral[100],
                                    '&:hover': {
                                        color: theme.palette.techatomic.neutral[100],
                                        backgroundColor: theme.palette.brands.x
                                    },
                                    mx: '.25rem'
                                }}
                                style={{
                                    minWidth: '10px',
                                    width: '36px'
                                }}
                            >
                                <XIcon />
                            </TaButton>

                            {/*INSTAGRAM*/}
                            <TaButton
                                size='medium'
                                sx={{
                                    color: theme.palette.techatomic.neutral[100],
                                    '&:hover': {
                                        color: theme.palette.techatomic.neutral[100],
                                        backgroundColor: instagramGradient
                                    },
                                    mx: '.25rem'
                                }}
                                style={{
                                    minWidth: '10px',
                                    width: '36px'
                                }}
                            >
                                <InstagramIcon />
                            </TaButton>

                            {/*LINKEDIN*/}
                            <TaButton
                                size='medium'
                                sx={{
                                    color: theme.palette.techatomic.neutral[100],
                                    '&:hover': {
                                        color: theme.palette.techatomic.neutral[100],
                                        backgroundColor: theme.palette.brands.linkedIn
                                    },
                                    mx: '.25rem'
                                }}
                                style={{
                                    minWidth: '10px',
                                    width: '36px'
                                }}
                            >
                                <LinkedInIcon />
                            </TaButton>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Footer;