import React, { createContext, useMemo, useState, useContext } from 'react';
import { ThemeProvider, CssBaseline, useMediaQuery } from '@mui/material';

import darkTheme from '../darkTheme';
import lightTheme from '../lightTheme';

const ColorModeContext = createContext({
    toggleColorMode: () => { },
    isDarkMode: false
});

export const useColorMode = () => useContext(ColorModeContext);

export const TechatomicThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

    const [isDarkMode, setIsDarkMode] = useState(prefersDarkMode);

    const toggleColorMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const theme = useMemo(() => (isDarkMode ? darkTheme : lightTheme), [isDarkMode]);

    return (
        <ColorModeContext.Provider value={{ toggleColorMode, isDarkMode }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};