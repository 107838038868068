import React from 'react';
import './header.css';

/*MUI COMPONENTS*/
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

/*REACT ROUTER COMPONENTS*/
import { HashLink } from 'react-router-hash-link';

/*ICON COMPONENTS*/
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';

/*SHARED COMPONENTS*/
import TaButton from '../../common/TaButton/TaButton';

/*IMAGE COMPONENTS*/
import darkThemeLogo from 'assets/images/techatomic/logos/png/ta-logo-dark.png';
import lightThemeLogo from 'assets/images/techatomic/logos/png/ta-logo-light.png';
import darkThemeIcon from 'assets/images/techatomic/icons/png/ta-icon-dark.png';
import lightThemeIcon from 'assets/images/techatomic/icons/png/ta-icon-light.png';

/*THEME COMPONENTS*/
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useTheme } from '@mui/material/styles';
import { useColorMode } from '../../../assets/themes/components/ColorModeContext';

/*NAVIGATION VALUES*/
const navLinks = [
    { id: 1, name: 'Home', path: '/', region: '#home' },
    { id: 2, name: 'Mission Statement', path: '/', region: '#mission-statement' },
    { id: 3, name: 'Meet the Team', path: '/', region: '#meet-the-team' },
    { id: 4, name: 'Our Product Suite', path: '/', region: '#our-product-suite' },
    { id: 5, name: 'Newsletter', path: '/', region: '#newsletter' }
];

//const authLinks = [
//    { id: 1, name: 'Login', path: '/login' },
//    { id: 2, name: 'Register', path: '/register' }
//];

const userLinks = [
    { id: 1, name: 'Dashboard', path: '/account', activeTab: 0},
    { id: 2, name: 'Product Licenses', path: '/account', activeTab: 1 },
    { id: 3, name: 'Security', path: '/account', activeTab: 2 },
    { id: 4, name: 'Settings', path: '/account', activeTab: 3 },
    { id: 5, name: 'Login', path: '/login' },
    { id: 6, name: 'Register', path: '/register' }
    /*{ id: 7, name: 'Logout', path: '/logout' },*/
];

/*APP ELEVATION*/
interface ElevationProps {
    window?: () => Window;
    children: React.ReactElement;
    enableElevation: boolean;
}

interface HeaderProps {
    enableElevation: boolean;
}

const ElevationScroll: React.FC<ElevationProps> = (props) => {
    const { children, window, enableElevation } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined
    });

    const theme = useTheme();

    const headerElevation = enableElevation ? (trigger ? 24 : 0) : 24;
    const headerBackgroundColor = enableElevation
        ? (trigger ? theme.palette.header : `transparent`)
        : theme.palette.header;

    return React.cloneElement(children, {
        elevation: headerElevation,
        style: {
            transition: 'all 0.3s ease',
            backgroundColor: headerBackgroundColor,
            backgroundImage: 'none'
        }
    });
};

const Header: React.FC<HeaderProps> = ({ enableElevation }) => {
    const theme = useTheme();
    const { isDarkMode } = useColorMode();
    const logo = !isDarkMode ? darkThemeLogo : lightThemeLogo;
    const icon = !isDarkMode ? darkThemeIcon : lightThemeIcon;

    /*NAVIGATION MENU*/
    const [openNav, setOpenNav] = React.useState(false);
    const anchorRefNav = React.useRef<HTMLButtonElement>(null);

    const handleToggleNav = () => {
        setOpenNav(true);
    };

    const handleCloseNav = (event: Event | React.SyntheticEvent) => {
        if (anchorRefNav.current && anchorRefNav.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpenNav(false);
    };

    function handleNavListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenNav(false);
        } else if (event.key === 'Escape') {
            setOpenNav(false);
        }
    }

    /*USER ACCOUNT MENU*/
    const [openUser, setOpenUser] = React.useState(false);
    const anchorRefUser = React.useRef<HTMLButtonElement>(null);

    const handleToggleUser = () => {
        setOpenUser(true);
    };

    const handleCloseUser = (event: Event | React.SyntheticEvent) => {
        if (anchorRefUser.current && anchorRefUser.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpenUser(false);
    };

    function handleUserListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenUser(false);
        } else if (event.key === 'Escape') {
            setOpenUser(false);
        }
    }

    return (
        <React.Fragment>
            {/*ELEVATION SUPPORT*/}
            <ElevationScroll
                enableElevation={enableElevation}
            >
                <AppBar
                    style={{
                        backgroundImage: 'none'
                    }}
                    sx={{
                        maxHeight: '64px',
                        minWidth: '320px'
                    }}
                >
                    <Container
                        maxWidth="xl"
                    >
                        <Toolbar
                            sx={{
                                justifyContent: "center"
                            }}
                            disableGutters
                        >
                            {/*NAVIGATION MENU*/}
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: {
                                        xs: 'flex'
                                    }
                                }}
                            >
                                <TaButton
                                    ref={anchorRefNav}
                                    aria-label="Navigation Menu"
                                    aria-controls={openNav ? 'composition-menu' : undefined}
                                    aria-expanded={openNav ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggleNav}
                                    color="inherit"
                                    style={{
                                        minWidth: '10px',
                                        width: '36px'
                                    }}
                                >
                                    <MenuIcon />
                                </TaButton>

                                <Popper
                                    open={openNav}
                                    anchorEl={anchorRefNav.current}
                                    role={undefined}
                                    placement="bottom-start"
                                    transition
                                    disablePortal
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                                            }}
                                        >
                                            <Paper
                                                sx={{
                                                    backgroundColor: theme.palette.headerMenu.backgroundColor
                                                }}
                                            >
                                                <ClickAwayListener onClickAway={handleCloseNav}>
                                                    <MenuList
                                                        autoFocusItem={openNav}
                                                        id="composition-menu"
                                                        aria-labelledby="composition-button"
                                                        onKeyDown={handleNavListKeyDown}
                                                    >
                                                        {navLinks.map((navLink) => (
                                                            <HashLink
                                                                smooth
                                                                key={navLink.id}
                                                                to={`${navLink.path}${navLink.region ? navLink.region : ''}`}
                                                                style={{ textDecoration: 'none', color: theme.palette.headerButton.text }}
                                                            >
                                                                <MenuItem
                                                                    onClick={handleCloseUser}
                                                                    sx={{
                                                                        textDecoration: 'none',
                                                                        color: theme.palette.headerButton.text,
                                                                        backgroundColor: 'initial',
                                                                        transition: 'background-color 0.3s ease',
                                                                        '&:hover': {
                                                                            backgroundColor: theme.palette.headerMenuItem.backgroundColorHover,
                                                                        },
                                                                    }}
                                                                >
                                                                    <Typography textAlign="center">{navLink.name}</Typography>
                                                                </MenuItem>
                                                            </HashLink>
                                                        ))}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Box>

                            {/*TECHATOMIC IMAGE*/}
                            <HashLink
                                smooth
                                to="/#home"
                                style={{
                                    display: 'flex',
                                    marginRight: 1,
                                    position: 'absolute',
                                    alignItems: 'center'
                                }}
                            >
                                {/*LOGO - LFF*/}
                                <Box
                                    component="img"
                                    sx={{
                                        height: 30,
                                        display: {
                                            xs: 'none',
                                            md: 'flex'
                                        }
                                    }}
                                    alt="Techatomic"
                                    src={logo}
                                />

                                {/*ICON - SFF*/}
                                <Box
                                    component="img"
                                    sx={{
                                        height: 30,
                                        display: {
                                            xs: 'flex',
                                            md: 'none'
                                        }                                        
                                    }}
                                    alt="Techatomic"
                                    src={icon}
                                />
                            </HashLink>

                            {/*USER AVATAR*/}
                            {/*<Box*/}
                            {/*    sx={{*/}
                            {/*        flexGrow: 0,*/}
                            {/*        display: {*/}
                            {/*            xs: 'flex'*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <TaButton*/}
                            {/*        ref={anchorRefUser}*/}
                            {/*        size="medium"*/}
                            {/*        aria-label="Account Settings"*/}
                            {/*        aria-controls={openUser ? 'user-menu' : undefined}*/}
                            {/*        aria-expanded={openUser ? 'true' : undefined}*/}
                            {/*        aria-haspopup="true"*/}
                            {/*        onClick={handleToggleUser}*/}
                            {/*        color={"inherit"}*/}
                            {/*        style={{*/}
                            {/*            minWidth: '10px',*/}
                            {/*            width: '36px'*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <AccountCircleIcon />*/}
                            {/*    </TaButton>*/}

                            {/*    <Popper*/}
                            {/*        open={openUser}*/}
                            {/*        anchorEl={anchorRefUser.current}*/}
                            {/*        role={undefined}*/}
                            {/*        placement="bottom-end"*/}
                            {/*        transition*/}
                            {/*        disablePortal*/}
                            {/*    >*/}
                            {/*        {({ TransitionProps, placement }) => (*/}
                            {/*            <Grow*/}
                            {/*                {...TransitionProps}*/}
                            {/*                style={{*/}
                            {/*                    transformOrigin:*/}
                            {/*                        placement === 'bottom-end' ? 'right top' : 'right bottom',*/}
                            {/*                }}*/}
                            {/*            >*/}
                            {/*                <Paper*/}
                            {/*                    sx={{*/}
                            {/*                        backgroundColor: theme.palette.headerMenu.backgroundColor*/}
                            {/*                    }}*/}
                            {/*                >*/}
                            {/*                    <ClickAwayListener onClickAway={handleCloseUser}>*/}
                            {/*                        <MenuList*/}
                            {/*                            autoFocusItem={openUser}*/}
                            {/*                            id="user-menu"*/}
                            {/*                            aria-labelledby="user-button"*/}
                            {/*                            onKeyDown={handleUserListKeyDown}*/}
                            {/*                        >*/}
                            {/*                            {userLinks.map((userLink) => (*/}
                            {/*                                <HashLink*/}
                            {/*                                    smooth*/}
                            {/*                                    key={userLink.id}*/}
                            {/*                                    to={`${userLink.path}`}*/}
                            {/*                                    style={{*/}
                            {/*                                        textDecoration: 'none',*/}
                            {/*                                        color: theme.palette.headerButton.text*/}
                            {/*                                    }}*/}
                            {/*                                >*/}
                            {/*                                    <MenuItem*/}
                            {/*                                        onClick={handleCloseUser}*/}
                            {/*                                        style={{*/}
                            {/*                                            textDecoration: 'none',*/}
                            {/*                                            color: theme.palette.headerButton.text,*/}
                            {/*                                            backgroundColor: 'initial',*/}
                            {/*                                            transition: 'background-color 0.3s ease'*/}
                            {/*                                        }}*/}
                            {/*                                        onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = theme.palette.headerMenuItem.backgroundColorHover; }}*/}
                            {/*                                        onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'initial'; }}*/}
                            {/*                                    >*/}
                            {/*                                        <Typography textAlign="center">{userLink.name}</Typography>*/}
                            {/*                                    </MenuItem>*/}
                            {/*                                </HashLink>*/}
                            {/*                            ))}*/}
                            {/*                            <Divider*/}
                            {/*                                sx={{*/}
                            {/*                                    my: 1,*/}
                            {/*                                    borderColor: theme.palette.headerMenu.divider*/}
                            {/*                                }}*/}
                            {/*                            />*/}
                            {/*                            <HashLink*/}
                            {/*                                smooth*/}
                            {/*                                key={7}*/}
                            {/*                                to={"/logout"}*/}
                            {/*                                style={{*/}
                            {/*                                    textDecoration: 'none',*/}
                            {/*                                    color: theme.palette.headerButton.text                                                                */}
                            {/*                                }}*/}
                            {/*                            >*/}
                            {/*                                <MenuItem*/}
                            {/*                                    onClick={handleCloseUser}*/}
                            {/*                                    style={{*/}
                            {/*                                        textDecoration: 'none',*/}
                            {/*                                        color: theme.palette.headerButton.text,*/}
                            {/*                                        backgroundColor: 'initial',*/}
                            {/*                                        transition: 'background-color 0.3s ease'*/}
                            {/*                                    }}*/}
                            {/*                                    onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = theme.palette.headerMenuItem.backgroundColorHover; }}*/}
                            {/*                                    onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'initial'; }}*/}
                            {/*                                >*/}
                            {/*                                    <Typography textAlign="center">Logout</Typography>*/}
                            {/*                                </MenuItem>*/}
                            {/*                            </HashLink>*/}
                            {/*                        </MenuList>*/}
                            {/*                    </ClickAwayListener>*/}
                            {/*                </Paper>*/}
                            {/*            </Grow>*/}
                            {/*        )}*/}
                            {/*    </Popper>*/}
                            {/*</Box>*/}
                        </Toolbar>
                    </Container>
                </AppBar>
            </ElevationScroll>  
        </React.Fragment>
    );
}

export default Header;