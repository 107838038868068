import { Route, Routes } from 'react-router-dom';
import './App.css';

/*MUI COMPONENTS*/
import Box from '@mui/material/Box';

/*MODULE COMPONENTS*/
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import Account from './pages/Account/Account';

/*CUSTOM COMPONENTS*/
import TaSpeedDial from './components/layout/TaSpeedDial/TaSpeedDial';

/*THEME COMPONENTS*/
import { TechatomicThemeProvider } from './assets/themes/components/ColorModeContext';

function App() {
    return (
        <TechatomicThemeProvider>
            <Box className="app">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/logout" element={<Home />} />
                </Routes>

                <TaSpeedDial />
            </Box>
        </TechatomicThemeProvider>
    );
}

export default App;
