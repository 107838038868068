import { createTheme } from '@mui/material/styles';
import commonTheme from './commonTheme';

const lightTheme = createTheme({
    ...commonTheme,
    palette: {
        ...commonTheme.palette,
        mode: 'light',
        /*SYSTEM PALETTES*/
        primary: {
            light: commonTheme.palette.techatomic.primary[700],
            main: commonTheme.palette.techatomic.primary[800],
            dark: commonTheme.palette.techatomic.primary[900],
            contrastText: commonTheme.palette.techatomic.primary[100]
        },
        secondary: {
            light: commonTheme.palette.techatomic.neutral[600],
            main: commonTheme.palette.techatomic.neutral[800],
            dark: commonTheme.palette.techatomic.neutral[900],
            contrastText: commonTheme.palette.techatomic.neutral[100]
        },
        background: {
            default: commonTheme.palette.techatomic.neutral[900],
            paper: commonTheme.palette.techatomic.neutral[800],
        },
        text: {
            primary: commonTheme.palette.techatomic.neutral[100],
            secondary: commonTheme.palette.techatomic.neutral[200],
            disabled: commonTheme.palette.techatomic.neutral[500]
        },
        divider: commonTheme.palette.techatomic.neutral[100],
        success: {
            light: commonTheme.palette.techatomic.success[700],
            main: commonTheme.palette.techatomic.success[800],
            dark: commonTheme.palette.techatomic.success[900],
            contrastText: commonTheme.palette.techatomic.success[100]
        },
        error: {
            light: commonTheme.palette.techatomic.error[700],
            main: commonTheme.palette.techatomic.error[800],
            dark: commonTheme.palette.techatomic.error[900],
            contrastText: commonTheme.palette.techatomic.error[100]
        },
        warning: {
            light: commonTheme.palette.techatomic.warning[700],
            main: commonTheme.palette.techatomic.warning[800],
            dark: commonTheme.palette.techatomic.warning[900],
            contrastText: commonTheme.palette.techatomic.warning[100]
        },
        info: {
            light: commonTheme.palette.techatomic.info[700],
            main: commonTheme.palette.techatomic.info[800],
            dark: commonTheme.palette.techatomic.info[900],
            contrastText: commonTheme.palette.techatomic.info[100]
        },
        action: {
            active: commonTheme.palette.techatomic.neutral[200],
            hover: commonTheme.palette.techatomic.neutral[500],
            hoverOpacity: 0.1,
            selected: commonTheme.palette.techatomic.neutral[600],
            selectedOpacity: 0.2,
            disabled: commonTheme.palette.techatomic.neutral[600],
            disabledBackground: commonTheme.palette.techatomic.neutral[700],
            disabledOpacity: 0.3,
            focus: commonTheme.palette.techatomic.neutral[400],
            focusOpacity: 0.2,
            activatedOpacity: 0.3
        },
        /*COMMOM PALETTES*/
        copyright: {
            text: commonTheme.palette.techatomic.neutral[200]
        },
        footer: commonTheme.palette.techatomic.neutral[1200],
        footerButton: {
            text: commonTheme.palette.techatomic.neutral[200],
            background: 'transparent',
            backgroundHover: commonTheme.palette.techatomic.neutral[800]
        },
        footerDivider: commonTheme.palette.techatomic.neutral[200],
        footerTextLink: {
            active: commonTheme.palette.techatomic.neutral[200],
            hover: commonTheme.palette.techatomic.neutral[800]
        },
        header: commonTheme.palette.techatomic.neutral[1300],
        headerButton: {
            text: commonTheme.palette.techatomic.neutral[200]
        },
        headerMenu: {
            backgroundColor: commonTheme.palette.techatomic.neutral[1200],
            divider: commonTheme.palette.techatomic.neutral[200]
        },
        headerMenuItem: {
            text: commonTheme.palette.techatomic.neutral[200],
            backgroundColor: commonTheme.palette.techatomic.neutral[1500],
            backgroundColorHover: commonTheme.palette.techatomic.neutral[1000]
        },
        register: commonTheme.palette.techatomic.neutral[800],
        textField: {
            active: commonTheme.palette.techatomic.neutral[100],
            hover: commonTheme.palette.techatomic.neutral[400],
            focused: commonTheme.palette.techatomic.neutral[500]
        },
        taButton: {
            text: commonTheme.palette.techatomic.neutral[200],
            background: 'transparent',
            backgroundHover: commonTheme.palette.techatomic.neutral[1100]
        }
    }
});

export default lightTheme;