import React from 'react';
import '../../../src/index.css';
import './home.css';

/*MUI COMPONENTS*/
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

/*SHARED COMPONENTS*/
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';
import TaButton from '../../components/common/TaButton/TaButton';

/*THEME COMPONENTS*/
import { useColorMode } from '../../assets/themes/components/ColorModeContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/*IMAGE COMPONENTS*/
/*TEAM - HEADSHOTS*/
import ashleyKennardHeadshot from 'assets/images/techatomic/headshots/ta-headshot-ashley-kennard.png'
import brianWagnerHeadshot from 'assets/images/techatomic/headshots/ta-headshot-brian-wagner.jpg'
import davidSchwersteinHeadshot from 'assets/images/techatomic/headshots/ta-headshot-david-schwerstein.png'
import jasonParkerHeadshot from 'assets/images/techatomic/headshots/ta-headshot-jason-parker.png'
import seanSzpunarHeadshot from 'assets/images/techatomic/headshots/ta-headshot-sean-szpunar.png'
import sidWashingtonHeadshot from 'assets/images/techatomic/headshots/ta-headshot-sid-washington.png'

/*MARKETING - ACTIVITIES*/
import marketingActivity1 from 'assets/images/techatomic/marketing/activities/ta-activity-1.jpg'
import marketingActivity2 from 'assets/images/techatomic/marketing/activities/ta-activity-2.jpg'
import marketingActivity3 from 'assets/images/techatomic/marketing/activities/ta-activity-3.jpg'

/*MARKETING - MISSIONS*/
import marketingMission1 from 'assets/images/techatomic/marketing/missions/ta-mission-1.jpg'
import marketingMission2 from 'assets/images/techatomic/marketing/missions/ta-mission-2.jpg'
import marketingMission3 from 'assets/images/techatomic/marketing/missions/ta-mission-3.jpg'

/*MARKETING - PROCESSES*/
import marketingProcess1 from 'assets/images/techatomic/marketing/processes/ta-process-1.jpg'
import marketingProcess2 from 'assets/images/techatomic/marketing/processes/ta-process-2.jpg'
import marketingProcess3 from 'assets/images/techatomic/marketing/processes/ta-process-3.jpg'
import marketingProcess4 from 'assets/images/techatomic/marketing/processes/ta-process-4.jpg'

/*MARKETING - PRODUCTS*/
import marketingProductInspire from 'assets/images/techatomic/marketing/products/ta-product-inspire.jpg'

/*MARKETING - RESEARCH*/
import marketingResearch1 from 'assets/images/techatomic/marketing/research/ta-research-1.jpg'
import marketingResearch2 from 'assets/images/techatomic/marketing/research/ta-research-2.jpg'
import marketingResearch3 from 'assets/images/techatomic/marketing/research/ta-research-3.jpg'

/*TEMPORARY CONTENT*/
import { techatomicPalette } from '../../assets/themes/palettes/techatomicPalette';

function Home() {
    const theme = useTheme();
    const isScreenLessThanMedium = useMediaQuery(theme.breakpoints.down('xs') || theme.breakpoints.down('sm'));
    const { isDarkMode } = useColorMode();

    return (
        /*HOME CONTENT*/
        <div
            id="home"
            className="background-color-neutral-1300"
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh'
            }}
        >
            {/*HOME CONTENT - HEADER*/}
            <Header enableElevation={true} />

            {/*HOME CONTENT - BODY*/}
            <div
                style={{
                    flex: '1 0 auto'
                }}
            >
                <Container
                    maxWidth="xl"
                >
                    <Grid
                        container
                    >
                        {/*BANNER*/}
                        <Grid
                            item
                            xs={12}
                            sx={{         
                                padding: {
                                    xs: '64px 0px 0px 0px',
                                    sm: '80px 0px 0px 0px',
                                    md: '109px 0px 29px 0px',
                                    lg: '133px 0px 29px 0px',
                                    xl: '133px 0px 29px 0px'
                                },
                                mt: '64px',
                                mb: {
                                    xs: 8,
                                    sm: 10,
                                    md: 10,
                                    lg: 13,
                                    xl: 13
                                }
                            }}
                        >
                            {/*BANNER CONTENT*/}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                {/*BANNER CONTENT - HEADING TEXT*/}
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: {
                                            xs: '2.5rem',
                                            sm: '3rem',
                                            md: '3.5rem',
                                            lg: '3.5rem'
                                        },
                                        lineHeight: {
                                            xs: '2.5rem',
                                            sm: '3rem',
                                            md: '3.5rem',
                                            lg: '3.5rem',
                                        },
                                        mb: {
                                            xs: 2,
                                            sm: 1.5,
                                            md: 1
                                        },
                                        width: {
                                            xs: 288,
                                            sm: 540,
                                            md: 752
                                        }
                                    }}
                                >
                                    Welcome to Techatomic!
                                </Typography>

                                {/*BANNER CONTENT - BODY TEXT*/}
                                <Typography
                                    sx={{                                        
                                        fontWeight: 400,
                                        fontSize: {
                                            xs: '1rem',
                                            sm: '1.25rem',
                                            md: '1.5rem',
                                            lg: '1.5rem'
                                        },
                                        lineHeight: {
                                            xs: '1.25rem',
                                            sm: '1.5rem',
                                            md: '1.75rem',
                                            lg: '1.75rem'
                                        },
                                        width: {
                                            xs: 288,
                                            sm: 540,
                                            md: 752
                                        }
                                    }}
                                >
                                    We are dedicated to revolutionizing software development by putting our users at the heart of everything we do.
                                </Typography>
                            </Box>                            
                        </Grid>

                        {/*MISSION STATEMENT*/}
                        <Grid
                            id="mission-statement"
                            item
                            xs={12}
                            sx={{
                                padding: {
                                    xs: '0px 0px 0px 0px',
                                    sm: '0px 0px 0px 0px',
                                    md: '0rem 4.5rem 0rem 4.5rem'
                                },
                                mb: {
                                    xs: 8,
                                    sm: 10,
                                    md: 10,
                                    lg: 13,
                                    xl: 13
                                }
                            }}
                        >
                            {/*MISSION STATEMENT CONTENT*/}
                            <Grid
                                container
                                spacing={0}
                                sx={{
                                    width: '100%',
                                    margin: 0,
                                    padding: 0,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'stretch'
                                }}
                            >
                                {/*MISSION STATEMENT CONTENT - IMAGE CONTAINER*/}
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'stretch',
                                        overflow: 'hidden',
                                        paddingRight: {
                                            xs: 0,
                                            md: '1.5rem'
                                        },
                                        marginBottom: {
                                            xs: '1.5rem',
                                            md: 0
                                        }
                                    }}
                                >
                                    {/*MISSION STATEMENT CONTENT - IMAGE CONTAINER - IMAGE WRAPPER*/}
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: {
                                                xs: '296px',
                                                md: '100%'
                                            },
                                            position: 'relative'
                                        }}
                                    >
                                        {/*MISSION STATEMENT CONTENT - IMAGE CONTAINER - IMAGE WRAPPER - IMAGE*/}
                                        <Box
                                            component="img"
                                            src={marketingMission2}
                                            alt="Mission Statement"
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                borderRadius: '1rem 2rem'
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                {/*MISSION STATEMENT CONTENT - TEXT CONTAINER*/}
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    {/*MISSION STATEMENT CONTENT - TEXT CONTAINER - HEADING TEXT*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            textAlign: {
                                                xs: 'center',
                                                md: 'left'
                                            }
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: {
                                                    xs: '2.5rem',
                                                    sm: '3rem',
                                                    md: '3.5rem',
                                                    lg: '3.5rem'
                                                },
                                                lineHeight: {
                                                    xs: '2.5rem',
                                                    sm: '3rem',
                                                    md: '3.5rem',
                                                    lg: '3.5rem',
                                                },
                                                mb: {
                                                    xs: 2,
                                                    sm: 2,
                                                    md: 2
                                                }
                                            }}
                                        >
                                            Mission{isScreenLessThanMedium ? <br /> : ' '}Statement
                                        </Typography>
                                    </Grid>

                                    {/*MISSION STATEMENT CONTENT - TEXT CONTAINER - BODY TEXT*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            textAlign: 'left'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 300,
                                                fontSize: {
                                                    xs: '1rem',
                                                    sm: '1rem',
                                                    md: '1rem',
                                                    lg: '1rem'
                                                },
                                                lineHeight: {
                                                    xs: '1.25rem',
                                                    sm: '1.25rem',
                                                    md: '1.25rem',
                                                    lg: '1.25rem'
                                                },
                                                mb: {
                                                    xs: 3
                                                }
                                            }}
                                        >
                                            Our mission is to lead the market with innovative, intuitive, and user-centric solutions that empower individuals and businesses to achieve their fullest potential. We strive to create software that not only meets but exceeds our users' needs and expectations, fostering a seamless and engaging digital experience.
                                        </Typography>
                                    </Grid>

                                    {/*MISSION STATEMENT CONTENT - TEXT CONTAINER - BUTTON*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            textAlign: 'left'
                                        }}
                                    >
                                        {/*MISSION STATEMENT CONTENT - TEXT CONTAINER - BUTTON - LEARN MORE*/}
                                        <TaButton
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                color: `${isDarkMode ? theme.palette.techatomic.neutral[1500] : theme.palette.techatomic.neutral[1500]}`,
                                                backgroundColor: `${isDarkMode ? theme.palette.techatomic.primary[800] : theme.palette.techatomic.primary[800]}`,
                                                '&:hover': {
                                                    backgroundColor: `${isDarkMode ? theme.palette.techatomic.primary[900] : theme.palette.techatomic.primary[900]}`,
                                                },
                                            }}
                                        >
                                            LEARN MORE
                                        </TaButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*MEET THE TEAM*/}
                        <Grid
                            id="meet-the-team"
                            item
                            xs={12}
                            sx={{
                                padding: {
                                    xs: '0px 0px 0px 0px',
                                    sm: '0px 0px 0px 0px',
                                    md: '0rem 4.5rem 0rem 4.5rem'
                                },
                                mb: {
                                    xs: 8,
                                    sm: 10,
                                    md: 10,
                                    lg: 13,
                                    xl: 13
                                }
                            }}
                        >
                            {/*MEET THE TEAM CONTENT WRAPPER*/}
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    padding: '0.5rem',
                                    border: '1px solid #F8F8F8',
                                    borderRadius: '1rem 2rem'
                                }}
                            >
                                {/*MEET THE TEAM CONTENT*/}
                                <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                        padding: {
                                            xs: '0.5rem 0rem'
                                        },
                                        margin: '-0.5rem'
                                    }}
                                >
                                    {/*MEET THE TEAM CONTENT - HEADING*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            textAlign: 'center',
                                            borderRadius: '0.5rem 1.5rem 0.25rem 0.25rem',
                                            padding: '0',
                                            ml: '0.5rem'
                                        }}
                                        className="background-color-neutral-1200"
                                    >
                                        {/*MEET THE TEAM CONTENT - HEADING - TEXT*/}
                                        <Typography
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: {
                                                    xs: '2rem',
                                                    sm: '3rem',
                                                    lg: '3.5rem'
                                                },
                                                lineHeight: {
                                                    xs: '40px',
                                                    sm: '48px',
                                                    lg: '64px',
                                                },
                                                padding: '0.5rem 0rem 0rem 0rem',
                                                mb: {
                                                    xs: 2
                                                }
                                            }}
                                        >
                                            Meet the Team
                                        </Typography>
                                    </Grid>

                                    {/*MEET THE TEAM CONTENT - JASON PARKER*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        sx={{
                                            minHeight: '21rem',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {/*MEET THE TEAM CONTENT - JASON PARKER - CONTENT WRAPPER*/}
                                        <Box
                                            sx={{
                                                borderRadius: {
                                                    xs: '0.25rem'
                                                },                                                
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}
                                            className="background-color-neutral-1100"
                                        >
                                            {/*MEET THE TEAM CONTENT - JASON PARKER - CONTENT WRAPPER - IMAGE*/}
                                            <Box                                                
                                                sx={{
                                                    width: 200,
                                                    height: 200,
                                                    padding: '12px',
                                                    borderRadius: '50%',
                                                    mb: 1
                                                }}
                                                className="background-color-neutral-1300"
                                            >
                                                <Box
                                                    component="img"
                                                    src={jasonParkerHeadshot}
                                                    alt="Jason Parker (CEO)"
                                                    sx={{
                                                        width: 176,
                                                        height: 176,
                                                        borderRadius: '50%',
                                                        objectFit: 'cover'
                                                    }}
                                                    className="border-3px-solid-neutral-200"
                                                />
                                            </Box>

                                            {/*MEET THE TEAM CONTENT - JASON PARKER - CONTENT WRAPPER - TEXT*/}
                                            <Box>
                                                {/*MEET THE TEAM CONTENT - JASON PARKER - CONTENT WRAPPER - TEXT - NAME*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '2rem',
                                                            md: '2rem',
                                                        }
                                                    }}
                                                >
                                                    Jason Parker
                                                </Typography>

                                                {/*MEET THE TEAM CONTENT - JASON PARKER - CONTENT WRAPPER - TEXT - TITLE*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: {
                                                            xs: '1rem',
                                                            md: '1rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem',
                                                        },
                                                    }}
                                                >
                                                    Chief Executive Officer
                                                </Typography>
                                            </Box> 
                                        </Box>
                                    </Grid>

                                    {/*MEET THE TEAM CONTENT - BRIAN WAGNER*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        sx={{
                                            minHeight: '21rem',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {/*MEET THE TEAM CONTENT - BRIAN WAGNER - CONTENT WRAPPER*/}
                                        <Box
                                            sx={{
                                                borderRadius: {
                                                    xs: '0.25rem'
                                                },
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}
                                            className="background-color-neutral-1100"
                                        >
                                            {/*MEET THE TEAM CONTENT - BRIAN WAGNER - CONTENT WRAPPER - IMAGE*/}
                                            <Box
                                                sx={{
                                                    width: 200,
                                                    height: 200,
                                                    padding: '12px',
                                                    borderRadius: '50%',
                                                    mb: 1
                                                }}
                                                className="background-color-neutral-1300"
                                            >
                                                <Box
                                                    component="img"
                                                    src={brianWagnerHeadshot}
                                                    alt="Brian Wagner (COO)"
                                                    sx={{
                                                        width: 176,
                                                        height: 176,
                                                        borderRadius: '50%',
                                                        objectFit: 'cover'
                                                    }}
                                                    className="border-3px-solid-neutral-200"
                                                />
                                            </Box>

                                            {/*MEET THE TEAM CONTENT - BRIAN WAGNER - CONTENT WRAPPER - TEXT*/}
                                            <Box>
                                                {/*MEET THE TEAM CONTENT - BRIAN WAGNER - CONTENT WRAPPER - TEXT - NAME*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '2rem',
                                                            md: '2rem',
                                                        }
                                                    }}
                                                >
                                                    Brian Wagner
                                                </Typography>

                                                {/*MEET THE TEAM CONTENT - BRIAN WAGNER - CONTENT WRAPPER - TEXT - TITLE*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: {
                                                            xs: '1rem',
                                                            md: '1rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem',
                                                        },
                                                    }}
                                                >
                                                    Chief Operating Officer
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    {/*MEET THE TEAM CONTENT - SID WASHINGTON*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        sx={{
                                            minHeight: '21rem',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {/*MEET THE TEAM CONTENT - SID WASHINGTON - CONTENT WRAPPER*/}
                                        <Box
                                            sx={{
                                                borderRadius: {
                                                    xs: '0.25rem'
                                                },
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}
                                            className="background-color-neutral-1100"
                                        >
                                            {/*MEET THE TEAM CONTENT - SID WASHINGTON - CONTENT WRAPPER - IMAGE*/}
                                            <Box
                                                sx={{
                                                    width: 200,
                                                    height: 200,
                                                    padding: '12px',
                                                    borderRadius: '50%',
                                                    mb: 1
                                                }}
                                                className="background-color-neutral-1300"
                                            >
                                                <Box
                                                    component="img"
                                                    src={sidWashingtonHeadshot}
                                                    alt="Sid Washington (CFO)"
                                                    sx={{
                                                        width: 176,
                                                        height: 176,
                                                        borderRadius: '50%',
                                                        objectFit: 'cover'
                                                    }}
                                                    className="border-3px-solid-neutral-200"
                                                />
                                            </Box>

                                            {/*MEET THE TEAM CONTENT - SID WASHINGTON - CONTENT WRAPPER - TEXT*/}
                                            <Box>
                                                {/*MEET THE TEAM CONTENT - SID WASHINGTON - CONTENT WRAPPER - TEXT - NAME*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '2rem',
                                                            md: '2rem',
                                                        }
                                                    }}
                                                >
                                                    Sid Washington
                                                </Typography>

                                                {/*MEET THE TEAM CONTENT - SID WASHINGTON - CONTENT WRAPPER - TEXT - TITLE*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: {
                                                            xs: '1rem',
                                                            md: '1rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem',
                                                        },
                                                    }}
                                                >
                                                    Chief Financial Officer
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    {/*MEET THE TEAM CONTENT - SEAN SZPUNAR*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        sx={{
                                            minHeight: '21rem',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {/*MEET THE TEAM CONTENT - SEAN SZPUNAR - CONTENT WRAPPER*/}
                                        <Box
                                            sx={{
                                                borderRadius: {
                                                    sm: '0.25rem 0.25rem 0.25rem 0.25rem',
                                                    md: '0.25rem 0.25rem 0.25rem 1.5rem'
                                                },
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}
                                            className="background-color-neutral-1100"
                                        >
                                            {/*MEET THE TEAM CONTENT - SEAN SZPUNAR - CONTENT WRAPPER - IMAGE*/}
                                            <Box
                                                sx={{
                                                    width: 200,
                                                    height: 200,
                                                    padding: '12px',
                                                    borderRadius: '50%',
                                                    mb: 1
                                                }}
                                                className="background-color-neutral-1300"
                                            >
                                                <Box
                                                    component="img"
                                                    src={seanSzpunarHeadshot}
                                                    alt="Sean Szpunar (CTO)"
                                                    sx={{
                                                        width: 176,
                                                        height: 176,
                                                        borderRadius: '50%',
                                                        objectFit: 'cover'
                                                    }}
                                                    className="border-3px-solid-neutral-200"
                                                />
                                            </Box>

                                            {/*MEET THE TEAM CONTENT - SEAN SZPUNAR - CONTENT WRAPPER - TEXT*/}
                                            <Box>
                                                {/*MEET THE TEAM CONTENT - SEAN SZPUNAR - CONTENT WRAPPER - TEXT - NAME*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '2rem',
                                                            md: '2rem',
                                                        }
                                                    }}
                                                >
                                                    Sean Szpunar
                                                </Typography>

                                                {/*MEET THE TEAM CONTENT - SEAN SZPUNAR - CONTENT WRAPPER - TEXT - TITLE*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: {
                                                            xs: '1rem',
                                                            md: '1rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem',
                                                        },
                                                    }}
                                                >
                                                    Chief Technology Officer
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    {/*MEET THE TEAM CONTENT - DAVID SCHWERSTEIN*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        sx={{
                                            minHeight: '21rem',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {/*MEET THE TEAM CONTENT - DAVID SCHWERSTEIN - CONTENT WRAPPER*/}
                                        <Box
                                            sx={{
                                                borderRadius: {
                                                    sm: '0.25rem 0.25rem 0.25rem 1.5rem',
                                                    md: '0.25rem 0.25rem 0.25rem 0.25rem'
                                                },
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}
                                            className="background-color-neutral-1100"
                                        >
                                            {/*MEET THE TEAM CONTENT - DAVID SCHWERSTEIN - CONTENT WRAPPER - IMAGE*/}
                                            <Box
                                                sx={{
                                                    width: 200,
                                                    height: 200,
                                                    padding: '12px',
                                                    borderRadius: '50%',
                                                    mb: 1
                                                }}
                                                className="background-color-neutral-1300"
                                            >
                                                <Box
                                                    component="img"
                                                    src={davidSchwersteinHeadshot}
                                                    alt="David Schwerstein (CIO)"
                                                    sx={{
                                                        width: 176,
                                                        height: 176,
                                                        borderRadius: '50%',
                                                        objectFit: 'cover'
                                                    }}
                                                    className="border-3px-solid-neutral-200"
                                                />
                                            </Box>

                                            {/*MEET THE TEAM CONTENT - DAVID SCHWERSTEIN - CONTENT WRAPPER - TEXT*/}
                                            <Box>
                                                {/*MEET THE TEAM CONTENT - DAVID SCHWERSTEIN - CONTENT WRAPPER - TEXT - NAME*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '2rem',
                                                            md: '2rem',
                                                        }
                                                    }}
                                                >
                                                    David Schwerstein
                                                </Typography>

                                                {/*MEET THE TEAM CONTENT - DAVID SCHWERSTEIN - CONTENT WRAPPER - TEXT - TITLE*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: {
                                                            xs: '1rem',
                                                            md: '1rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem',
                                                        },
                                                    }}
                                                >
                                                    Chief Information Officer
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    {/*MEET THE TEAM CONTENT - ASHLEY KENNARD*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        sx={{
                                            minHeight: '21rem',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {/*MEET THE TEAM CONTENT - ASHLEY KENNARD - CONTENT WRAPPER*/}
                                        <Box
                                            sx={{
                                                borderRadius: {
                                                    xs: '0.25rem 0.25rem 0.5rem 1.5rem',
                                                    sm: '0.25rem 0.25rem 0.5rem 0.25rem'
                                                },
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}
                                            className="background-color-neutral-1100"
                                        >
                                            {/*MEET THE TEAM CONTENT - ASHLEY KENNARD - CONTENT WRAPPER - IMAGE*/}
                                            <Box
                                                sx={{
                                                    width: 200,
                                                    height: 200,
                                                    padding: '12px',
                                                    borderRadius: '50%',
                                                    mb: 1
                                                }}
                                                className="background-color-neutral-1300"
                                            >
                                                <Box
                                                    component="img"
                                                    src={ashleyKennardHeadshot}
                                                    alt="Ashley Kennard (CDO)"
                                                    sx={{
                                                        width: 176,
                                                        height: 176,
                                                        borderRadius: '50%',
                                                        objectFit: 'cover'
                                                    }}
                                                    className="border-3px-solid-neutral-200"
                                                />
                                            </Box>

                                            {/*MEET THE TEAM CONTENT - ASHLEY KENNARD - CONTENT WRAPPER - TEXT*/}
                                            <Box>
                                                {/*MEET THE TEAM CONTENT - ASHLEY KENNARD - CONTENT WRAPPER - TEXT - NAME*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '2rem',
                                                            md: '2rem',
                                                        }
                                                    }}
                                                >
                                                    Ashley Kennard
                                                </Typography>

                                                {/*MEET THE TEAM CONTENT - ASHLEY KENNARD - CONTENT WRAPPER - TEXT - TITLE*/}
                                                <Typography
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: {
                                                            xs: '1rem',
                                                            md: '1rem'
                                                        },
                                                        lineHeight: {
                                                            xs: '1.5rem',
                                                            md: '1.5rem',
                                                        },
                                                    }}
                                                >
                                                    Chief Design Officer
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        {/*IMAGE TRANSITION 1*/}
                        <Grid
                            container
                            xs={12}
                            sx={{
                                padding: {
                                    xs: '0px 0px 0px 0px',
                                    sm: '0px 0px 0px 0px',
                                    md: '0rem 4.5rem 0rem 4.5rem'
                                },
                                mb: {
                                    xs: 8,
                                    sm: 10,
                                    md: 10,
                                    lg: 13,
                                    xl: 13
                                },
                                justifyContent: 'space-between'
                            }}
                        >
                            {/*IMAGE TRANSITION 1 - IMAGE 1*/}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: {
                                        xs: '0 0 0.5rem 0',
                                        sm: '0 0.25rem 0.5rem 0',
                                        md: '0 0.5rem 0 0'
                                    }
                                }}
                            >
                                {/*IMAGE TRANSITION 1 - IMAGE 1 - IMAGE WRAPPER*/}
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 0,
                                        paddingBottom: '100%',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderRadius: {
                                            xs: '1rem 2rem 0.25rem 0.25rem',
                                            sm: '1rem 0.25rem 0.25rem 0.25rem',
                                            md: '1rem 0.25rem 0.25rem 2rem'
                                        }
                                    }}
                                >
                                    {/*IMAGE TRANSITION 1 - IMAGE 1 - IMAGE WRAPPER - IMAGE*/}
                                    <Box
                                        component="img"
                                        src={marketingProcess2}
                                        alt="Image"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Box>
                            </Grid>

                            {/*IMAGE TRANSITION 1 - IMAGE 2*/}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: {
                                        xs: '0 0 0.5rem 0',
                                        sm: '0 0 0.5rem 0.25rem',
                                        md: '0 0.5rem 0 0'
                                    }
                                }}
                            >
                                {/*IMAGE TRANSITION 1 - IMAGE 2 - IMAGE WRAPPER*/}
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 0,
                                        paddingBottom: '100%',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderRadius: {
                                            xs: '0.25rem 0.25rem 0.25rem 0.25rem',
                                            sm: '0.25rem 2rem 0.25rem 0.25rem',
                                            md: '0.25rem 0.25rem 0.25rem 0.25rem'
                                        }
                                    }}
                                >
                                    {/*IMAGE TRANSITION 1 - IMAGE 2 - IMAGE WRAPPER - IMAGE*/}
                                    <Box
                                        component="img"
                                        src={marketingActivity1}
                                        alt="Image"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Box>
                            </Grid>

                            {/*IMAGE TRANSITION 1 - IMAGE 3*/}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: {
                                        xs: '0 0 0.5rem 0',
                                        sm: '0 0.25rem 0 0',
                                        md: '0 0.5rem 0 0'
                                    }
                                }}
                            >
                                {/*IMAGE TRANSITION 1 - IMAGE 3 - IMAGE WRAPPER*/}
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 0,
                                        paddingBottom: '100%',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderRadius: {
                                            xs: '0.25rem 0.25rem 0.25rem 0.25rem',
                                            sm: '0.25rem 0.25rem 0.25rem 2rem',
                                            md: '0.25rem 0.25rem 0.25rem 0.25rem'
                                        }
                                    }}
                                >
                                    {/*IMAGE TRANSITION 1 - IMAGE 3 - IMAGE WRAPPER - IMAGE*/}
                                    <Box
                                        component="img"
                                        src={marketingResearch1}
                                        alt="Image"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Box>
                            </Grid>

                            {/*IMAGE TRANSITION 1 - IMAGE 4*/}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: {                                        
                                        xs: 0,
                                        sm: '0 0 0 0.25rem',
                                        md: '0 0.5rem 0 0'
                                    },
                                    mr: {
                                        md: '-0.5rem'  
                                    }
                                }}
                            >
                                {/*IMAGE TRANSITION 1 - IMAGE 4 - IMAGE WRAPPER*/}
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 0,
                                        paddingBottom: '100%',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderRadius: {
                                            xs: '0.25rem 0.25rem 1rem 2rem',
                                            sm: '0.25rem 0.25rem 1rem 0.25rem',
                                            md: '0.25rem 2rem 1rem 0.25rem'
                                        }
                                    }}
                                >
                                    {/*IMAGE TRANSITION 1 - IMAGE 4 - IMAGE WRAPPER - IMAGE*/}
                                    <Box
                                        component="img"
                                        src={marketingActivity2}
                                        alt="Image"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        {/*OUR PRODUCT SUITE*/}
                        <Grid
                            id="our-product-suite"
                            item
                            xs={12}
                            sx={{
                                padding: {
                                    xs: '0px 0px 0px 0px',
                                    sm: '0px 0px 0px 0px',
                                    md: '0rem 4.5rem 0rem 4.5rem'
                                },
                                mb: {
                                    xs: 8,
                                    sm: 10,
                                    md: 10,
                                    lg: 13,
                                    xl: 13
                                }
                            }}
                        >
                            {/*OUR PRODUCT SUITE CONTENT*/}
                            <Grid
                                container
                            >
                                {/*OUR PRODUCT SUITE CONTENT - HEADING*/}
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: {
                                                xs: '2.5rem',
                                                sm: '3rem',
                                                md: '3.5rem',
                                                lg: '3.5rem'
                                            },
                                            lineHeight: {
                                                xs: '2.5rem',
                                                sm: '3rem',
                                                md: '3.5rem',
                                                lg: '3.5rem',
                                            },
                                            mb: 2
                                        }}
                                    >
                                        Our Product Suite
                                    </Typography>
                                </Grid>

                                {/*OUR PRODUCT SUITE CONTENT - INSPIRE*/}
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        padding: 0
                                    }}
                                >
                                    {/*PRODUCT IMAGE*/}
                                    <Box
                                        component="img"
                                        src={marketingProductInspire}
                                        alt="Inspire"
                                        sx={{
                                            borderRadius: '1rem 2rem 0rem 0rem',
                                            width: '100%',
                                            height: '312px',
                                            display: 'block',
                                            objectFit: 'cover'
                                        }}
                                    />

                                    {/*PRODUCT DETAILS*/}
                                    <Box
                                        sx={{
                                            borderRadius: '0rem 0rem 1rem 2rem',
                                            padding: '1rem'
                                        }}
                                        className="background-color-neutral-1100"
                                    >
                                        {/*PRODUCT TEXT*/}
                                        <Box
                                            sx={{
                                                width: '100%',
                                                textAlign: 'left',
                                                mb: '1rem'
                                            }}
                                        >
                                            {/*PRODUCT TITLE*/}
                                            <Typography
                                                sx={{
                                                    fontWeight: 400,
                                                    fontSize: {
                                                        xs: '1.5rem',
                                                        md: '1.5rem'
                                                    },
                                                    lineHeight: {
                                                        xs: '2rem',
                                                        md: '2rem',
                                                    }
                                                }}
                                            >
                                                Inspire
                                            </Typography>

                                            {/*PRODUCT SUBTITLE*/}
                                            <Typography
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: {
                                                        xs: '1rem',
                                                        md: '1rem'
                                                    },
                                                    lineHeight: {
                                                        xs: '1.5rem',
                                                        md: '1.5rem',
                                                    },
                                                    mb: '0.5rem'
                                                }}
                                            >
                                                Next Generation Gifting
                                            </Typography>

                                            {/*PRODUCT SLOGAN*/}
                                            <Typography
                                                sx={{
                                                    fontWeight: 300,
                                                    fontSize: {
                                                        xs: '1rem',
                                                        md: '1rem'
                                                    },
                                                    lineHeight: {
                                                        xs: '1.5rem',
                                                        md: '1.5rem',
                                                    },
                                                }}
                                            >
                                                Revolutionize your gifting experience with a next-gen solution for cultivating memorable moments.
                                            </Typography>
                                        </Box>

                                        {/*PRODUCT ACTION*/}
                                        <Box
                                            sx={{
                                                width: '100%',
                                                textAlign: 'right'
                                            }}
                                        >
                                            {/*OPEN BUTTON*/}
                                            <TaButton
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    color: `${isDarkMode ? theme.palette.techatomic.neutral[1500] : theme.palette.techatomic.neutral[1500]}`,
                                                    backgroundColor: `${isDarkMode ? theme.palette.techatomic.primary[800] : theme.palette.techatomic.primary[800]}`,
                                                    '&:hover': {
                                                        backgroundColor: `${isDarkMode ? theme.palette.techatomic.primary[900] : theme.palette.techatomic.primary[900]}`,
                                                    },
                                                }}
                                            >
                                                OPEN
                                            </TaButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*IMAGE TRANSITION 2*/}
                        <Grid
                            container
                            xs={12}
                            sx={{
                                padding: {
                                    xs: '0px 0px 0px 0px',
                                    sm: '0px 0px 0px 0px',
                                    md: '0rem 4.5rem 0rem 4.5rem'
                                },
                                mb: {
                                    xs: 8,
                                    sm: 10,
                                    md: 10,
                                    lg: 13,
                                    xl: 13
                                },
                                justifyContent: 'space-between'
                            }}
                        >
                            {/*IMAGE TRANSITION 2 - IMAGE 1*/}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: {
                                        xs: '0 0 0.5rem 0',
                                        sm: '0 0.25rem 0.5rem 0',
                                        md: '0 0.5rem 0 0'
                                    }
                                }}
                            >
                                {/*IMAGE TRANSITION 2 - IMAGE 1 - IMAGE WRAPPER*/}
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 0,
                                        paddingBottom: '100%',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderRadius: {
                                            xs: '1rem 2rem 0.25rem 0.25rem',
                                            sm: '1rem 0.25rem 0.25rem 0.25rem',
                                            md: '1rem 0.25rem 0.25rem 2rem'
                                        }
                                    }}
                                >
                                    {/*IMAGE TRANSITION 2 - IMAGE 1 - IMAGE WRAPPER - IMAGE*/}
                                    <Box
                                        component="img"
                                        src={marketingProcess3}
                                        alt="Image"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Box>
                            </Grid>

                            {/*IMAGE TRANSITION 2 - IMAGE 2*/}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: {
                                        xs: '0 0 0.5rem 0',
                                        sm: '0 0 0.5rem 0.25rem',
                                        md: '0 0.5rem 0 0'
                                    }
                                }}
                            >
                                {/*IMAGE TRANSITION 2 - IMAGE 2 - IMAGE WRAPPER*/}
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 0,
                                        paddingBottom: '100%',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderRadius: {
                                            xs: '0.25rem 0.25rem 0.25rem 0.25rem',
                                            sm: '0.25rem 2rem 0.25rem 0.25rem',
                                            md: '0.25rem 0.25rem 0.25rem 0.25rem'
                                        }
                                    }}
                                >
                                    {/*IMAGE TRANSITION 2 - IMAGE 2 - IMAGE WRAPPER - IMAGE*/}
                                    <Box
                                        component="img"
                                        src={marketingActivity3}
                                        alt="Image"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Box>
                            </Grid>

                            {/*IMAGE TRANSITION 2 - IMAGE 3*/}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: {
                                        xs: '0 0 0.5rem 0',
                                        sm: '0 0.25rem 0 0',
                                        md: '0 0.5rem 0 0'
                                    }
                                }}
                            >
                                {/*IMAGE TRANSITION 2 - IMAGE 3 - IMAGE WRAPPER*/}
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 0,
                                        paddingBottom: '100%',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderRadius: {
                                            xs: '0.25rem 0.25rem 0.25rem 0.25rem',
                                            sm: '0.25rem 0.25rem 0.25rem 2rem',
                                            md: '0.25rem 0.25rem 0.25rem 0.25rem'
                                        }
                                    }}
                                >
                                    {/*IMAGE TRANSITION 2 - IMAGE 3 - IMAGE WRAPPER - IMAGE*/}
                                    <Box
                                        component="img"
                                        src={marketingProcess4}
                                        alt="Image"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Box>
                            </Grid>

                            {/*IMAGE TRANSITION 2 - IMAGE 4*/}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: {
                                        xs: 0,
                                        sm: '0 0 0 0.25rem',
                                        md: '0 0.5rem 0 0'
                                    },
                                    mr: {
                                        md: '-0.5rem'
                                    }
                                }}
                            >
                                {/*IMAGE TRANSITION 2 - IMAGE 4 - IMAGE WRAPPER*/}
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 0,
                                        paddingBottom: '100%',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderRadius: {
                                            xs: '0.25rem 0.25rem 1rem 2rem',
                                            sm: '0.25rem 0.25rem 1rem 0.25rem',
                                            md: '0.25rem 2rem 1rem 0.25rem'
                                        }
                                    }}
                                >
                                    {/*IMAGE TRANSITION 2 - IMAGE 4 - IMAGE WRAPPER - IMAGE*/}
                                    <Box
                                        component="img"
                                        src={marketingProcess1}
                                        alt="Image"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        {/*NEWSLETTER*/}
                        <Grid
                            id="newsletter"
                            item
                            xs={12}
                            sx={{
                                padding: {
                                    xs: '0px 0px 0px 0px',
                                    sm: '0px 0px 0px 0px',
                                    md: '0rem 4.5rem 0rem 4.5rem'
                                },
                                mb: {
                                    xs: 13,
                                    sm: 13,
                                    md: 13,
                                    lg: 13,
                                    xl: 13
                                }
                            }}
                        >
                            {/*NEWSLETTER CONTENT*/}
                            <Grid
                                container
                                spacing={0}
                                sx={{
                                    width: '100%',
                                    margin: 0,
                                    padding: 0,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'stretch'
                                }}
                            >
                                {/*NEWSLETTER CONTENT - IMAGE CONTAINER*/}
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'stretch',
                                        overflow: 'hidden',
                                        paddingRight: {
                                            xs: 0,
                                            md: '1.5rem'
                                        },
                                        marginBottom: {
                                            xs: '1.5rem',
                                            md: 0
                                        }
                                    }}
                                >
                                    {/*NEWSLETTER CONTENT - IMAGE CONTAINER - IMAGE WRAPPER*/}
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: {
                                                xs: '296px',
                                                md: '100%'
                                            },
                                            position: 'relative'
                                        }}
                                    >
                                        {/*NEWSLETTER CONTENT - IMAGE CONTAINER - IMAGE WRAPPER - IMAGE*/}
                                        <Box
                                            component="img"
                                            src={marketingResearch2}
                                            alt="Newsletter"
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                borderRadius: '1rem 2rem'
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                {/*NEWSLETTER CONTENT - FORM CONTAINER*/}
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    {/*NEWSLETTER CONTENT - FORM CONTAINER - HEADING TEXT*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            textAlign: {
                                                xs: 'center',
                                                md: 'left'
                                            }
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: {
                                                    xs: '2.5rem',
                                                    sm: '3rem',
                                                    md: '3.5rem',
                                                    lg: '3.5rem'
                                                },
                                                lineHeight: {
                                                    xs: '2.5rem',
                                                    sm: '3rem',
                                                    md: '3.5rem',
                                                    lg: '3.5rem',
                                                },
                                                mb: {
                                                    xs: 2,
                                                    sm: 2,
                                                    md: 2
                                                }
                                            }}
                                        >
                                            Newsletter
                                        </Typography>
                                    </Grid>

                                    {/*NEWSLETTER CONTENT - FORM CONTAINER - BODY TEXT*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            textAlign: 'left'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 300,
                                                fontSize: {
                                                    xs: '1rem',
                                                    sm: '1rem',
                                                    md: '1rem',
                                                    lg: '1rem'
                                                },
                                                lineHeight: {
                                                    xs: '1.25rem',
                                                    sm: '1.25rem',
                                                    md: '1.25rem',
                                                    lg: '1.25rem'
                                                },
                                                mb: {
                                                    xs: 2
                                                }
                                            }}
                                        >
                                            Stay ahead of the curve with exclusive updates, expert insights, and special offers delivered straight to your inbox - sign up for our newsletter today!
                                        </Typography>
                                    </Grid>

                                    {/*NEWSLETTER CONTENT - FORM CONTAINER - INPUT GROUP*/}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            textAlign: 'right'
                                        }}
                                    >
                                        {/*NEWSLETTER CONTENT - FORM CONTAINER - INPUT GROUP CONTAINER*/}
                                        <Grid
                                            container
                                            sx={{
                                                [theme.breakpoints.up('md')]: {
                                                    alignItems: 'center'
                                                }
                                            }}
                                        >
                                            {/*NEWSLETTER CONTENT - FORM CONTAINER - INPUT GROUP CONTAINER - TEXT*/}
                                            <Grid
                                                item
                                                xs={12}
                                                md
                                                sx={{
                                                    [theme.breakpoints.up('md')]: {
                                                        flexGrow: 1
                                                    }
                                                }}
                                            >
                                                <TextField
                                                    id="newsletter-email-address"
                                                    label="Email Address"
                                                    variant="filled"
                                                    size="small"
                                                    sx={{
                                                        width: '100%',
                                                        paddingRight: {
                                                            xs: 0,
                                                            md: 2
                                                        },
                                                        marginBottom: {
                                                            xs: 2,
                                                            md: 0
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            {/*NEWSLETTER CONTENT - FORM CONTAINER - INPUT GROUP CONTAINER - BUTTON*/}
                                            <Grid
                                                item
                                                xs={12}
                                                md="auto"
                                                sx={{
                                                    textAlign: {
                                                        xs: 'left',
                                                        md: 'right'
                                                    }
                                                }}
                                            >
                                                <TaButton
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{
                                                        mt: 0.5,
                                                        color: `${isDarkMode ? theme.palette.techatomic.neutral[1500] : theme.palette.techatomic.neutral[1500]}`,
                                                        backgroundColor: `${isDarkMode ? theme.palette.techatomic.primary[800] : theme.palette.techatomic.primary[800]}`,
                                                        '&:hover': {
                                                            backgroundColor: `${isDarkMode ? theme.palette.techatomic.primary[900] : theme.palette.techatomic.primary[900]}`,
                                                        },
                                                    }}
                                                >
                                                    SIGN ME UP!
                                                </TaButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>                  

            {/*HOME CONTENT - FOOTER*/}
            <Footer />
        </div>
    );
}

export default Home;