import { Button } from '@mui/material';
import { styled } from '@mui/system';

const TaButton = styled(Button)(({ theme }) => ({
    borderRadius: '5px 10px 5px 10px',
    transition: 'all 0.3s ease',
    color: theme.palette.taButton.text,
    backgroundColor: theme.palette.taButton.background,
    '&:hover': {
        backgroundColor: theme.palette.taButton.backgroundHover,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
    },
    '& .MuiTouchRipple-child': {
        borderRadius: "inherit",
    }
}));

export default TaButton;