import { useState } from 'react';
import './register.css';

/*MUI COMPONENTS*/
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import GlobalStyles from "@mui/material/GlobalStyles";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

/*ICON COMPONENTS*/
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

/*SHARED COMPONENTS*/
import TaButton from "../../components/common/TaButton/TaButton";

/*THEME COMPONENTS*/
import { alpha, useTheme } from '@mui/material/styles';
import { useColorMode } from '../../assets/themes/components/ColorModeContext';
import darkThemeLogo from 'assets/images/techatomic/logos/png/ta-logo-light.png';
import lightThemeLogo from 'assets/images/techatomic/logos/png/ta-logo-dark.png';
//import darkThemeIcon from 'assets/images/techatomic/icons/png/ta-icon-white.png';
//import lightThemeIcon from 'assets/images/techatomic/icons/png/ta-icon-black.png';

/*MARKETING - MISSIONS*/
import marketingMission1 from 'assets/images/techatomic/marketing/missions/ta-mission-1.jpg';

/*GLOBAL STYLE*/
const globalStyle = {
    "input::-ms-reveal, input::-ms-clear": {
        display: "none"
    }
};

/*COPYRIGHT INFORMATION*/
function Copyright() {
    const copyright = String.fromCodePoint(0x00A9);

    return (
        <div
            className="copyright"
        >
            <Typography
                variant="body2"
                align="center"
                sx={{
                    mt: 4,
                }}
            >
                Copyright {copyright} {new Date().getFullYear()} Techatomic. All Rights Reserved.
            </Typography>

            {/*<Typography>*/}
            {/*    Trademarks and brands are the property of their respective owners.*/}
            {/*</Typography>*/}
        </div>
    );
}

function Register() {
    const theme = useTheme();
    const { isDarkMode } = useColorMode();
    const logo = isDarkMode ? darkThemeLogo : lightThemeLogo;
    /*const icon = !isDarkMode ? darkThemeIcon : lightThemeIcon;*/

    /*FIRST NAME CHANGE*/
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState('');

    const handleFirstNameChange = (event: { target: { value: any; }; }) => {
        const firstNameValue = event.target.value;
        setFirstName(firstNameValue);
        if (firstNameValue) {
            setFirstNameError('');
        }
    }

    /*LAST NAME CHANGE*/
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState('');

    const handleLastNameChange = (event: { target: { value: any; }; }) => {
        const lastNameValue = event.target.value;
        setLastName(lastNameValue);
        if (lastNameValue) {
            setLastNameError('');
        }
    }

    /*EMAIL CHANGE*/
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleEmailChange = (event: { target: { value: any; }; }) => {
        const emailValue = event.target.value;
        setEmail(emailValue);
    };

    const handleEmailBlur = () => {
        if (email) {
            if (!isValidEmail(email)) {
                setEmailError('That is not a valid email address!');
            }
            else {
                setEmailError('');
            }
        }
    }

    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    /*PASSWORD CHANGE*/
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordChange = (event: { target: { value: any; }; }) => {
        setPassword(event.target.value);
    };

    const handlePasswordBlur = () => {
        if (password) {
            if (!isValidPassword(password)) {
                setPasswordError('Your password must be at least 10 characters in length and contain at least one digit, one lowercase letter, one uppercase letter, one special character, and one unique character!');
            }
            else {
                setPasswordError('');
            }
        }
    }

    const isValidPassword = (password: string) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()_+=[\]{}\\/;:'"",<>\\|.?-]).{10,}$/;
        return passwordRegex.test(password);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    /*FORM SUBMIT*/
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!firstName) {
            setFirstNameError('A first name is required!');
        }

        if (!lastName) {
            setLastNameError('A last name is required!');
        }

        if (!email) {
            setEmailError('An email address is required!');
        }
        else if (!isValidEmail(email)) {
            setEmailError('That is not a valid email address!');
        }

        if (!password) {
            setPasswordError('A password is required!');
        }
        else if (!isValidPassword(password)) {
            setPasswordError('Your password must be at least 10 characters in length and contain at least one digit, one lowercase letter, one uppercase letter, one special character, and one unique character!');
        }

        console.log('First Name:', firstName);
        console.log('Last Name:', lastName);
        console.log('Email:', email);
        console.log('Password:', password);

        return;
    };

    return (
        /*REGISTER CONTENT*/
        <div
            id="register"
            className="background-color-neutral-1300"
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh'
            }}
        >
            {/*REGISTER CONTENT - BODY*/}
            <Box
                sx={{
                    height: '100vh',
                    width: '100vw',
                    backgroundImage: `url(${marketingMission1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <GlobalStyles styles={globalStyle} />

                <Container
                    component="main"
                    maxWidth="xs"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        justifyContent: 'center',
                        borderRadius: '1rem 2rem',
                        backgroundColor: alpha(theme.palette.techatomic.neutral[1500], 0.75),
                        backdropFilter: 'blur(5px)',
                        padding: {
                            xs: 6,
                            sm: 6,
                            md: 6
                        },
                        marginLeft: {
                            xs: 2,
                            sm: 0
                        },
                        marginRight: {
                            xs: 2,
                            sm: 0
                        }
                    }}
                >
                    {/*REGISTER CONTENT - BODY CONTENT*/}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',                            
                            flexGrow: 1
                        }}
                    >
                        {/*REGISTER CONTENT - BODY CONTENT - TECHATOMIC LOGO*/}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '3.5rem',
                                mb: 1
                            }}
                        >
                            <Link
                                to={`/`}
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit',

                                    marginBottom: '32px',
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        height: '3.5rem'
                                    }}
                                    alt="Techatomic"
                                    src={logo}
                                />
                            </Link>
                        </Box>

                        {/*REGISTER CONTENT - BODY CONTENT - HEADING*/}
                        <Typography
                            component="h1"
                            variant="h5"
                            sx={{
                                mt: 2,
                                mb: 3,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            Register
                        </Typography>

                        {/*REGISTER CONTENT - BODY CONTENT - FORM*/}
                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{
                                mb: 0,
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%'
                            }}
                        >
                            {/*REGISTER CONTENT - BODY CONTENT - FORM CONTENT - FIELDS*/}
                            <Grid
                                container
                                spacing={2}
                            >
                                {/*REGISTER CONTENT - BODY CONTENT - FORM CONTENT - FIELDS - FIRST NAME*/}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                        autoComplete="given-name"
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        error={!!firstNameError}
                                        helperText={firstNameError}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: firstNameError ? theme.palette.techatomic.error[500] : theme.palette.textField.active,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: firstNameError ? theme.palette.techatomic.error[500] : theme.palette.textField.hover,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: firstNameError ? theme.palette.techatomic.error[500] : theme.palette.textField.focused,
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: firstNameError ? theme.palette.techatomic.error[500] : theme.palette.textField.active
                                            },
                                            '& .MuiInputLabel-root.Mui-focused': {
                                                color: firstNameError ? theme.palette.techatomic.error[500] : theme.palette.textField.focused
                                            },
                                        }}
                                    />
                                </Grid>

                                {/*REGISTER CONTENT - BODY CONTENT - FORM CONTENT - FIELDS - LAST NAME*/}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="family-name"
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                        error={!!lastNameError}
                                        helperText={lastNameError}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: lastNameError ? theme.palette.techatomic.error[500] : theme.palette.textField.active,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: lastNameError ? theme.palette.techatomic.error[500] : theme.palette.textField.hover,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: lastNameError ? theme.palette.techatomic.error[500] : theme.palette.textField.focused,
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: lastNameError ? theme.palette.techatomic.error[500] : theme.palette.textField.active
                                            },
                                            '& .MuiInputLabel-root.Mui-focused': {
                                                color: lastNameError ? theme.palette.techatomic.error[500] : theme.palette.textField.focused
                                            },
                                        }}
                                    />
                                </Grid>

                                {/*REGISTER CONTENT - BODY CONTENT - FORM CONTENT - FIELDS - EMAIL ADDRESS*/}
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        onBlur={handleEmailBlur}
                                        error={!!emailError}
                                        helperText={emailError}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: emailError ? theme.palette.techatomic.error[500] : theme.palette.textField.active,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: emailError ? theme.palette.techatomic.error[500] : theme.palette.textField.hover,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: emailError ? theme.palette.techatomic.error[500] : theme.palette.textField.focused,
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: emailError ? theme.palette.techatomic.error[500] : theme.palette.textField.active
                                            },
                                            '& .MuiInputLabel-root.Mui-focused': {
                                                color: emailError ? theme.palette.techatomic.error[500] : theme.palette.textField.focused
                                            },
                                        }}
                                    />
                                </Grid>

                                {/*REGISTER CONTENT - BODY CONTENT - FORM CONTENT - FIELDS - PASSWORD*/}
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        autoComplete="current-password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        onBlur={handlePasswordBlur}
                                        error={!!passwordError}
                                        helperText={passwordError}
                                        InputProps={{
                                            endAdornment: (
                                                password && (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            sx={{
                                                                color: passwordError ? theme.palette.techatomic.error[500] : theme.palette.textField.active,
                                                            }}
                                                        >
                                                            {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            )
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: passwordError ? theme.palette.techatomic.error[500] : theme.palette.textField.active,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: passwordError ? theme.palette.techatomic.error[500] : theme.palette.textField.hover,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: passwordError ? theme.palette.techatomic.error[500] : theme.palette.textField.focused,
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: passwordError ? theme.palette.techatomic.error[500] : theme.palette.textField.active
                                            },
                                            '& .MuiInputLabel-root.Mui-focused': {
                                                color: passwordError ? theme.palette.techatomic.error[500] : theme.palette.textField.focused
                                            }
                                        }}
                                    />
                                </Grid>

                                {/*REGISTER CONTENT - BODY CONTENT - FORM CONTENT - FIELDS - NEWSLETTER SIGNUP*/}
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: theme.palette.techatomic.primary[800] }} />}
                                            checkedIcon={<CheckBoxIcon style={{ color: theme.palette.techatomic.primary[800] }} />}
                                            value="receiveNewsletter"
                                            color="secondary" />}
                                        label="I want to receive Techatomic news and product updates via email."
                                    />
                                </Grid>
                            </Grid>

                            {/*REGISTER CONTENT - BODY CONTENT - FORM CONTENT - SUBMIT*/}
                            <TaButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 4,
                                    mb: 2,
                                    color: `${isDarkMode ? theme.palette.techatomic.neutral[1500] : theme.palette.techatomic.neutral[1500]}`,
                                    backgroundColor: `${isDarkMode ? theme.palette.techatomic.primary[800] : theme.palette.techatomic.primary[800]}`,
                                    '&:hover': {
                                        backgroundColor: `${isDarkMode ? theme.palette.techatomic.primary[900] : theme.palette.techatomic.primary[900]}`,
                                    },
                                }}
                            >
                                Submit
                            </TaButton>

                            {/*REGISTER CONTENT - BODY CONTENT - FORM CONTENT - ALREADY REGISTERED?*/}
                            <Grid
                                container
                                justifyContent="center"
                            >
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: theme.palette.textField.active,
                                            transition: 'all 0.3s ease-in-out',
                                        }}
                                    >
                                        Already have a Techatomic account?{' '}
                                        <Box
                                            component={Link}
                                            to={`/login`}
                                            sx={{
                                                textDecoration: 'none',
                                                color: theme.palette.textLink.active,
                                                '&:hover': {
                                                    color: theme.palette.textLink.hover,
                                                },
                                            }}
                                        >
                                            Sign in.
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        {/*REGISTER CONTENT - BODY CONTENT - COPYRIGHT*/}
                        <Box
                            sx={{
                                mt: 'auto'                                
                            }}
                        >
                            <Copyright />
                        </Box>
                    </Box>
                </Container>
            </Box>            
        </div>        
    );
}

export default Register;