import React, { useEffect, useState } from 'react';
import './account.css';

/*AXIOS SUPPORT*/
import axios from 'axios';

/*MUI COMPONENTS*/
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

/*ICON COMPONENTS*/
import CancelRoundedIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/*SHARED COMPONENTS*/
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';
import ScrollToTopButton from '../../components/layout/ScrollToTopButton/ScrollToTopButton';
import TaButton from '../../components/common/TaButton/TaButton';

/*THEME COMPONENTS*/
import { useTheme } from '@mui/material/styles';
/*import banner from 'assets/images/techatomic/patterns/png/ta-pattern-black-fill.png';*/

const avatar = 'https://picsum.photos/160';
const inspire = 'https://picsum.photos/50';
const talaria = 'http://picsum.photos/50';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

interface Product {
    uniqueId: string;
    name: string;
    normalizedName: string;
    url: string | null;
    icon: string | null;
    dataLaunched: string;
}

function Dashboard() {
    const [expanded, setExpanded] = useState<string | false>(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [products, setProducts] = useState<Product[]>([]);
    const [productsLoading, setProductsLoading] = useState(true);
    const [productsError, setProductsError] = useState(null);

    const theme = useTheme();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        axios.get('http://localhost:1000/tah/gateway/products')
            .then(response => {
                const data = response.data;

                if (data.isSuccess) {
                    setProducts(data.result);
                }
                else {
                    setProductsError(data.message);
                }

                setProductsLoading(false);
            })
            .catch(error => {
                console.log("There was an error retrieving the products!", error);
                setProductsError(error);
                setProductsLoading(false);
            })
    }, []);

    if (productsLoading) {
        //LOADING CONTENT
    }

    if (productsError) {
        //ERROR CONTENT
    }

    return (
        /*ACCOUNT CONTENT*/
        <div
            id="account-dashboard"
            className="background-color-neutral-1300"
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh'
            }}
        >
            {/*ACCOUNT CONTENT - HEADER*/}
            <Header enableElevation={true} />

            {/*HOME CONTENT - BODY*/}
            <div
                style={{
                    flex: '1 0 auto'
                }}
            >
                <Container
                    maxWidth="xl"
                >
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                padding: {
                                    xs: '64px 0px 0px 0px',
                                    sm: '80px 0px 0px 0px',
                                    md: '109px 0px 29px 0px',
                                    lg: '133px 0px 29px 0px',
                                    xl: '133px 0px 29px 0px'
                                },
                                mt: '64px',
                                mb: {
                                    xs: 13,
                                    sm: 13,
                                    md: 13,
                                    lg: 13,
                                    xl: 13
                                },
                                height: '1500px'
                            }}
                            className="background-color-neutral-1200"
                        >

                        </Grid>
                    </Grid>
                </Container>
            </div>

            {/*HOME CONTENT - FOOTER*/}
            <Footer />
        </div>
    );
}

export default Dashboard;